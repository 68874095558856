<template>
  <div>
    <!--主体内容-->
    <div class="">
      <!--搜索条件区域-->
      <div class="box" v-loading="loading">
      	<div class="content_left">
  			<div class="name" style="margin:-4px auto 0">
			    <span>车场评分：</span>
				  <el-rate
				   v-model="grade"
				   disabled
				   style="display:inline-block; vertical-align:2px; margin:0 2px 0 2px"
				   :colors="['#ff9900', '#ff9900', '#ff9900']"
				   >
				   </el-rate>
				  <span>{{parkGrade}}</span>
			</div>          
			<p class="name">车场评级：<span class="info">{{parkRate}}</span></p>
		</div>
      	<div class="content_right">
		
		    <div class="tag">
			    <el-tag v-for="(item,index) in tagList" :key="index">
					{{item.name}}({{item.value}})
				</el-tag>			
			</div>	
		</div>		
      </div>
    </div>
  </div>
</template>
<script>
import 
'../../../../common/js/public.js';
export default {
  name: 'parkComment',
  props: {
	datas: {
	   type: Array,
	   default: []
	}			
  },
  data() {
    return {
		loading: false,
		parkGrade: '',
		grade: 0,
		parkRate: '暂无',
		spinner: [],
		tagList: []
    }
  },
  methods: {
  	getBasicData () {
      this.loading = true;
	  // let url = '/acb/2.0/park/' + this.$route.query.parkId;
	  let url = '/acb/2.0/memberParkEvaluation/getParkEvaluation?parkId=' + this.$route.query.parkId;
      this.$axios.get(url).then(res => {
        this.loading = false;
        if (res.state == 0) {
		       // let a = 15;
			   // let b = 2;
			   let labe
			   if (res.value) {
				   let totalScore = res.value.totalScore || 0;
				   let totalNumber = res.value.totalNumber || 0;			   
				   this.parkGrade = (totalScore / 2 / totalNumber).toFixed(1)

				   // this.parkGrade = (a / 2 / b).toFixed(1);
				   this.grade = Number(this.parkGrade);
				   let parkRate 
				   if (this.grade >= 4.5) {
						parkRate = '非常好'
				   } else if (this.grade < 4.5 && this.grade >= 4.0) {
						parkRate = '好'
				   } else if (this.grade <= 4.0 && this.grade > 3.0) {
						parkRate = '一般'
				   } else if (this.grade <= 3.0 && this.grade > 2.0) {
						parkRate = '差'
				   } else if (this.grade <= 2.0 && this.grade > 1.0) {
						parkRate = '非常差'
				   } else {
						parkRate = '非常差'				   
				   }
				   this.parkRate = parkRate;
				   labe = JSON.parse(res.value.labelMap) || {"1": 0, "2": 0, "3": 0, "4": 0, "5": 0, "6": 0};				
	//			   let c = {
	//			   	  "2": 6,
	//				  "4": 2,
	//				  "5": 9,
	//				  "6": 11,
	//				  "1": 32,
	//				  "3": 5	
	//			   }
				} else {  
				    labe = {"1": 0, "2": 0, "3": 0, "4": 0, "5": 0, "6": 0};
				}

				let tagArr = []
			    this.datas.forEach((item, index) => {
					 for (let i in labe) {
						 if (item.code == Number(i)) {
							if (labe[i] && labe[i] > 0) {
							tagArr.push({name: item.desc, value: labe[i]})
							}
						 	
							break; 
						 }
					 }				    
			    })
				
				this.tagList = tagArr;			   
				// console.log('组合分析:' + JSON.stringify(this.tagList))
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine')
          })
        }
      }).catch(error => {
	  	this.loading = false;
	  })		
	}
  },
  created() {
   	 // this.getSpinnerData() 	
  },
  mounted() {
   	 this.getBasicData() 	  	 
  },
  computed: {
  }
};
</script>

<style scoped>
.box{
  overflow:hidden;
  display:flex;
  align-items:center;
  height:107px;
}
.box .content_left{
  padding:40px 0;
  width:45%;
  box-sizing:border-box;
  border-right:1px dashed #cccccc;
  padding-left:10%;
}

.box .content_left .name{
	font-size:16px;
	margin:20px 0;
}

.box .content_left .name span{
	font-size:16px;
	display:inline-block;
}

.box .content_left .name .info{
	color:#0099ff;
	font-size:16px;
	font-weight:600;
	margin-left:5px;
}

.box .content_right{
  display:flex!important;
  width:55%;
  flex-direction:column;
  justify-content:center;
  text-align:center;
}

.box .content_right .tag{
	margin:0 auto 0;
}

.content_right .tag .el-tag{
	margin:4px 8px 4px;	
	min-width:108px;
	border:1px solid #ff0000;
	background:#fff;
	color:#ff0000;
	float:left;
	display:inline;
}

.content_right .tag .el-tag:nth-child(4){
	clear:both
}
/*整体*/
.yellow_star{
  display:inline-block;
  position:relative;
  width:140px;
  height:29px;
  top:9px;
}

/*整体-每排星星组*/
.yellow_star div{
    position:absolute;
    left:0;
    top:0;
    white-space:nowrap;
    overflow:hidden;
}

/*第一排-灰色星星组*/
.yellow_star div.back{
	z-index:2;
}

/*第一排-灰色星星组-图标*/
.yellow_star div.back i{
	width:28px;
	height:29px;
}


/*第二排-黄色星星组*/
.yellow_star div.front{
	z-index:3;
}

/*第一排-黄色星星组-图标*/
.yellow_star div.front i{
	width:28px;
	height:29px;
}
</style>

<style>
.content_left .el-rate__item{
	width:28px!important;
}
.content_left .el-rate__icon.el-icon-star-on::before{
	font-size:28px!important;

}

.content_left .el-rate__decimal.el-icon-star-on::before{
	font-size:28px!important;
	color:#ff9900;
}
</style>
